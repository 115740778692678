import { functions } from "@/firebaseConfig"
import { httpsCallable } from "firebase/functions"
import { mapState, mapGetters } from "vuex"

export default {
  computed: {
    ...mapState(["units", "supplierItems"]),
    ...mapGetters(["getUnitName"]),
  },
  methods: {
    /***************************************/
    /*   SUPPLIER ITEMS
    /***************************************/

    getUnitCostFromCostMoney(costMoney, supplierUnit) {
      return (
        (costMoney?.amount || 0) *
        parseFloat(this.units.find(unit => unit.id == supplierUnit)?.valInBase || 1)
      )
    },
    getCostMoneyFromUnitCost(unitCost, supplierUnit) {
      return {
        amount:
          unitCost /
          parseFloat(this.units.find(unit => unit.id == supplierUnit)?.valInBase),
        currency: "CAD",
        unit: this.units.find(unit => unit.id == supplierUnit)?.base,
      }
    },
    getCostPerUnit(item) {
      if (!item?.costMoney || !item.packageMeasurement.preferredDisplayUnitId)
        return 0
      return (
        (item.costMoney.amount || 0) *
        parseFloat(
          this.units.find(
            unit => unit.id == item.packageMeasurement.preferredDisplayUnitId
          )?.valInBase
        )
      )
    },
    getCostPerPackage(item) {
      if (
        !item?.costMoney ||
        !item.packageMeasurement?.quantity ||
        !item.packageMeasurement.linkedItemID
      ) {
        const supplierItem = this.supplierItems.find(
          supplierItem => supplierItem.id === item.supplierItemId
        )
        item = { ...item, ...supplierItem }
      }
      if (!item.packageMeasurement) {
        item.packageMeasurement = this.createEmptyMeasurementObject()
      }
      return (
        this.getUnitCostFromCostMoney(
          item.costMoney,
          item.packageMeasurement.preferredDisplayUnitId
        ) * this.getQuantityInBaseUnits(item.packageMeasurement.quantity, item.id)
      )
    },
    getCostPerPackageOrCase(item) {
      const caseMultiplier = item.quantityUnit === "pkgs" ? 1 : item.caseSize || 1
      return this.getCostPerPackage(item) * caseMultiplier
    },
    /**
     * Converts a quantity from a supplier item's unit to the base unit
     * @param {number} quantity The quantity to convert
     * @param {number} itemId The ID of the linked item
     * @returns {number} The converted quantity
     */
    getQuantityInBaseUnits(quantity, id) {
      // Find the supplier item with the given ID
      const supplierItem = this.supplierItems.find(item => item.id === id)

      if (supplierItem) {
        // Find the preferred display unit for the supplier item
        const displayUnit = this.units.find(
          unit => unit.id === supplierItem.packageMeasurement?.preferredDisplayUnitId
        )

        // Define the rounding factor
        const roundingFactor = 100

        if (displayUnit && displayUnit.base) {
          // If a display unit with a base value is found, convert the quantity to the base unit
          return (
            Math.round((quantity / displayUnit.valInBase) * roundingFactor) /
            roundingFactor
          )
        } else {
          // If no display unit with a base value is found, return the quantity rounded to two decimal places
          return Math.round(quantity * roundingFactor) / roundingFactor
        }
      }
    },
    getPackageMeasurementWithUnit(item) {
      if (!item.packageMeasurement) return "N/A"
      return (
        this.getQuantityInBaseUnits(item.packageMeasurement.quantity, item.id) +
        " " +
        this.getUnitName(
          item.packageMeasurement.preferredDisplayUnitId,
          this.getQuantityInBaseUnits(item.packageMeasurement.quantity, item.id) > 1
        )
      )
    },
    getCaseMeasurementWithUnit(item) {
      if (!item.packageMeasurement || !item.caseSize) return "N/A"
      const qty =
        this.getQuantityInBaseUnits(item.packageMeasurement.quantity, item.id) *
        item.caseSize
      return (
        qty +
        " " +
        this.getUnitName(
          item.packageMeasurement.preferredDisplayUnitId,
          this.getQuantityInBaseUnits(item.packageMeasurement.quantity, item.id) > 1
        )
      )
    },

    /***************************************/
    /*   ITEMS
    /***************************************/

    /**
     * Returns the total quantity of an item in the preferred display unit
     * @param {Object} item The item to get the total quantity of
     * @returns {string} The total quantity of the item with units
     */
    getTotalQuantityWithUnit(item) {
      let qty = 0
      if (item.quantity) {
        const supplierItem = this.supplierItems.find(
          supplierItem => supplierItem.linkedItemID === item.itemID
        )
        qty =
          item.quantity *
          this.getQuantityInBaseUnits(item.measurement?.quantity, supplierItem?.id)

        if (item.quantityUnit === "cases") {
          qty *= item.caseSize
        }
      }
      return (
        qty +
        " " +
        this.getUnitName(item.measurement.preferredDisplayUnitId, qty != 1)
      )
    },
    /**
     * Turn the measurement object into a text string for display
     * @param {Object} measurement The measurement object to convert
     * @returns {string} The measurement object as a text string
     * @example "5 kg"
     */
    getMeasurementForDisplay(measurement) {
      if (
        !measurement?.preferredDisplayUnitId ||
        !measurement.quantity ||
        !measurement.unit
      )
        return "0"

      let unit = this.units.find(u => u.id === measurement.preferredDisplayUnitId)
      if (unit) {
        return (
          Math.round((measurement.quantity / unit.valInBase) * 100) / 100 +
          " " +
          this.getUnitName(measurement.preferredDisplayUnitId, true)
        )
      } else {
        return Math.round(measurement.quantity * 100) / 100 + " " + measurement.unit
      }
    },
    createEmptyMeasurementObject() {
      return {
        quantity: null,
        unit: "",
        preferredDisplayUnitId: "",
      }
    },

    /***************************************/
    /*   MONEY
    /***************************************/

    formatMoneyAmount(amount, forSaving = true) {
      if (isNaN(amount) || !amount) amount = 0

      if (forSaving) return parseInt(amount)
      else {
        return new Intl.NumberFormat("en-CA", {
          style: "currency",
          currency: "CAD",
        }).format(parseFloat(amount / 100))
      }
    },

    /***************************************/
    /*   OPEN AI
    /***************************************/

    async getOpenAiAnswer(prompt, maxTokens = 50, temperature = 0) {
      try {
        const response = await fetch("https://api.openai.com/v1/completions", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.VUE_APP_OPENAI_API_KEY}`,
            //   "OpenAI-Organization": process.env.VUE_APP_OPENAI_ORG_ID,
          },
          body: JSON.stringify({
            model: "text-davinci-003",
            prompt: prompt,
            temperature: temperature,
            max_tokens: maxTokens,
          }),
        })

        const data = await response.json()
        return data.choices[0].text.trim()
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    },

    /***************************************/
    /*   BACKEND
    /***************************************/
    async getUidByEmail(email) {
      const response = await httpsCallable(functions, "getUidByEmail")(email)
      return response.data
    },
  },
}
