<template>
  <div>
    <v-row
      class="measurement-field"
      :class="{ 'with-unit-selection': allowUnitSelection }"
      no-gutters
    >
      <v-col :cols="allowUnitSelection ? 8 : 12">
        <v-text-field
          v-model="displayQuantity"
          :dense="!allowUnitSelection"
          outlined
          placeholder="Amount"
          :label="label"
          :suffix="!allowUnitSelection ? selectedUnitName : undefined"
          hide-details
          @input="handleChange"
        />
      </v-col>
      <v-col v-if="allowUnitSelection" class="pl-0" cols="4">
        <v-select
          v-model="displayUnitId"
          :items="unitsList"
          item-text="name"
          item-value="id"
          label="Unit"
          outlined
          hide-details
          :disabled="!allowUnitSelection"
          @input="handleChange"
        ></v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"

export default {
  name: "measurement-field",
  props: {
    value: {
      type: Object,
      required: false,
      default: () => this.createEmptyMeasurementObject(),
    },
    allowUnitSelection: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    itemId: {
      type: String,
      default: "",
    },
    selectedUnitId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      displayQuantity: "",
      displayUnitId: this.value?.preferredDisplayUnitId,
    }
  },
  computed: {
    ...mapGetters(["getUnitName"]),
    ...mapState(["units", "supplierItems"]),
    unitsList() {
      return this.associatedSupplierItem
        ? this.units.filter(u => u.base === this.thisItemUnit.base)
        : this.units
    },
    selectedUnitName() {
      if (this.selectedUnitId) {
        return this.getUnitName(this.selectedUnitId)
      }
      const unit = this.unitsList.find(u => u.id === this.displayUnitId)
      return unit ? unit.name : ""
    },
    associatedSupplierItem() {
      return this.itemId
        ? this.supplierItems.find(sItem => sItem.linkedItemID === this.itemId)
        : null
    },
    thisItemUnit() {
      return this.units.find(
        u =>
          u.id ===
          this.associatedSupplierItem?.packageMeasurement.preferredDisplayUnitId
      )
    },
  },
  watch: {
    value: {
      handler(newVal) {
        this.displayUnitId = newVal?.preferredDisplayUnitId
        this.displayQuantity = this.convertBaseToDisplayQuantity(newVal?.quantity)
      },
      deep: true,
      immediate: true,
    },
    itemId() {
      this.displayUnitId = this.associatedSupplierItem
        ? this.thisItemUnit.id
        : this.unitsList[0].id
      this.$emit("input", {
        unit: this.associatedSupplierItem ? this.thisItemUnit.base : "",
        quantity: this.convertDisplayToBaseQuantity(this.displayQuantity),
        preferredDisplayUnitId: this.displayUnitId,
      })
    },
    selectedUnitId() {
      this.displayUnitId = this.selectedUnitId
      this.handleChange()
    },
  },
  async mounted() {
    if (this.selectedUnitId) {
      this.displayUnitId = this.selectedUnitId
      this.handleChange()
    } else {
      this.displayUnitId = this.value?.preferredDisplayUnitId
      this.displayQuantity = this.convertBaseToDisplayQuantity(this.value?.quantity)
    }
  },
  methods: {
    handleChange() {
      this.$emit("input", {
        unit: this.getBaseUnitFromDisplayUnitId(),
        quantity: this.convertDisplayToBaseQuantity(this.displayQuantity),
        preferredDisplayUnitId: this.displayUnitId,
      })
    },
    convertDisplayToBaseQuantity(unit) {
      const itemUnit = this.units.find(u => u.id === this.displayUnitId)
      if (itemUnit && itemUnit.base) {
        return unit * itemUnit.valInBase
      } else {
        return unit
      }
    },
    convertBaseToDisplayQuantity(unit) {
      if (unit === 0) return 0
      if (!unit) return ""
      const itemUnit = this.units.find(u => u.id === this.displayUnitId)
      if (itemUnit && itemUnit.base) {
        return unit / itemUnit.valInBase
      } else {
        return unit
      }
    },
    getBaseUnitFromDisplayUnitId() {
      return this.units.find(u => u.id === this.displayUnitId)?.base || ""
    },
  },
}
</script>

<style lang="scss">
.measurement-field {
  &.with-unit-selection {
    .col:first-child fieldset {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      margin-right: -1px;
      border-right-color: transparent !important;
    }
    .col:last-child fieldset {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-left: -1px;
      border-left-color: transparent !important;
    }
  }
}
</style>
